$card-color: $text !default;
$card-background-color: $white !default;
$card-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

$card-header-background-color: transparent !default;
$card-header-color: $text-strong !default;
$card-header-shadow: 0 1px 2px rgba($black, 0.1) !default;
$card-header-weight: $weight-bold !default;

$card-content-background-color: transparent !default;

$card-footer-background-color: transparent !default;
$card-footer-border-top: 1px solid $border !default;

.card {
  background-color: $card-background-color;
  box-shadow: $card-shadow;
  color: $card-color;
  max-width: 100%;
  position: relative;
  border-radius: $radius-large;
  overflow: hidden;
  font-size: $size-normal;

  &.is-equal-height {
    height: 100%;
  }
}

.card-header {
  background-color: $card-header-background-color;
  align-items: stretch;
  box-shadow: $card-header-shadow;
  display: flex;
}

.card-header-title {
  align-items: center;
  color: $card-header-color;
  display: flex;
  flex-grow: 1;
  font-size: $size-5;
  font-weight: $card-header-weight;
  padding: 15px;

  @include from($tablet) {
    padding: 2.22rem 2.22rem 0.75rem 0.75rem;
  }

  &.is-centered {
    justify-content: center;
  }
}

.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px 0 15px 15px;

  @include from($tablet) {
    padding: 2.22rem 0 0.75rem 2.22rem;
  }
}

.card-image {
  display: block;
  position: relative;
}

.card-content {
  background-color: $card-content-background-color;
  padding: 0 15px 15px 15px;

  @include from($tablet) {
    padding: 0.75rem 2.22rem 2.22rem;
  }
}

.card-footer {
  background-color: $card-footer-background-color;
  border-top: $card-footer-border-top;
  align-items: stretch;
  display: flex;
}

.card-footer-item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.75rem;

  &:not(:last-child) {
    border-right: $card-footer-border-top;
  }
}

// Combinations

.card {
  .media:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}
