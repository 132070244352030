$section-padding: 5.5556rem 1.5rem !default;
$section-padding-medium: 9rem 1.5rem !default;
$section-padding-large: 18rem 1.5rem !default;

.section {
  padding: 30px 15px;
  @include from($tablet) {
    padding: $section-padding;
  }

  // Responsiveness
  @include desktop {
    // Sizes
    &.is-medium {
      padding: $section-padding-medium;
    }

    &.is-large {
      padding: $section-padding-large;
    }
  }
}
