$menu-item-color: $text !default;
$menu-item-radius: $radius-small !default;
$menu-item-hover-color: $primary !default;
$menu-item-hover-background-color: unset !default;
$menu-item-active-color: $primary !default;
$menu-item-active-background-color: unset !default;

$menu-list-border-left: 1px solid $border !default;

$menu-label-color: $text-light !default;

.menu {
  font-size: $size-normal;

  // Sizes
  &.is-small {
    font-size: $size-small;
  }

  &.is-medium {
    font-size: $size-medium;
  }

  &.is-large {
    font-size: $size-large;
  }
}

.menu-list {
  line-height: 1.25;

  a {
    border-radius: $menu-item-radius;
    color: $menu-item-color;
    display: block;
    padding: 0;

    &:hover {
      background-color: $menu-item-hover-background-color;
      color: $menu-item-hover-color;
    }

    // Modifiers
    &.is-active {
      background-color: $menu-item-active-background-color;
      color: $menu-item-active-color;
    }
  }

  li {
    margin-bottom: 20px;

    @include from($tablet) {
      margin-bottom: 2.25em;
    }
    
    ul {
      border-left: $menu-list-border-left;
      margin: 0.75em;
      padding-left: 0.75em;
    }
  }
}

.menu-label {
  color: $menu-label-color;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: 1em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

aside.menu {
  .menu-list {
    @include until($tablet) {
      display: flex;
      margin-right: -15px;
      margin-left: -15px;
      padding: 20px 0;
      background-color: $white;
      overflow: auto;
      @include overflow-touch;
      text-align: center;

      li {
        flex: 1 0 auto;
        margin-bottom: 0;

        a {
          padding: 0 10px;
        }

        &:first-child a {
          padding-left: 20px;
        }

        &:last-child a {
          padding-right: 20px;
        }
      }
    }
  }
}
