
.pin-box {
  display: flex;

  .pin-item {
    border: 1px solid #c4c4c4;
    padding-left: 27px;
    width: 75px;
    height: 90px;
    font-size: x-large;
    margin: 0 8px;
  }
}

.button-box {
  display: flex;
}

.message-reminder {
  color: #808080;
}

.progress-box {
  width: 100px;

  .fixed-label-parent {
    position: relative;
    width: 100%;
    background-color: $grey-lighter;
    color: $grey-darker;;

    .fixed-label {
      position: absolute;
    }
  }

  .progress-animation {
    animation-duration: 60s;
    animation-name: progress;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

@keyframes progress {
  from {
    color: $grey-lighter;
    background-color: $white;
  }

  to {
    color: $grey-darker;
    background-color: $grey-lighter;
  }
}
