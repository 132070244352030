$control-radius: 50px !default;
$control-radius-small: $radius-small !default;

$control-border-width: 0px !default;

$control-height-mobile: 52px;
$control-height: 72px !default;
$control-line-height: 1.5 !default;

$control-padding-vertical: calc(1.25em - #{$control-border-width}) !default;
$control-padding-horizontal: calc(1.75em - #{$control-border-width}) !default;

@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: $control-border-width solid transparent;
  border-radius: $control-radius;
  box-shadow: none;
  display: inline-flex;
  font-size: $size-6;
  height: 52px;
  justify-content: flex-start;
  line-height: $control-line-height;
  padding-bottom: $control-padding-vertical;
  padding-left: $control-padding-horizontal;
  padding-right: $control-padding-horizontal;
  padding-top: $control-padding-vertical;
  position: relative;
  vertical-align: top;

  @include from($tablet) {
    font-size: $size-normal;
    height: $control-height;
  }
 
  // States
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    outline: none;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

%control {
  @include control;
}

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
  border-radius: $control-radius-small;
  font-size: $size-small;
}

@mixin control-medium {
  font-size: $size-medium;
}

@mixin control-large {
  font-size: $size-large;
}
