@charset "utf-8";
$primary: rgb(111, 2, 181);
// @import "assets/styles/utilities/functions";
$torquoise: hsl(171, 100%, 41%);
$custom-colors: (torquoise: $torquoise);
@import "assets/styles/bulma";
@import "~bulma-tooltip";
$fa-font-path : "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "react-datepicker/src/stylesheets/datepicker.scss";
html{ height:100%; }
tfoot.total tr :first-child::before {
  content: "Total"
}
@include mobile {
  .container {
    margin: 0 0.75rem;
  }
}
.select__control {
  min-width: 200px;
}
.select__control--is-focused {
  border-color: transparent !important;
  box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color !important;
}
.compact {
  .input, .textarea, .select:not(.is-multiple)>select, .button {
    font-size: 1rem;
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(.375em - 1px);
    padding-left: calc(.625em - 1px);
    padding-right: calc(.625em - 1px);
    padding-top: calc(.375em - 1px);
  }
  .select:not(.is-multiple) {
    height: 2.25em;
  }
  .section {
    padding-top: 2px;
  }
  .input {
    width: 200px;
  }
}
$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
}
.loader.is-primary {
  border-left-color: $primary;
  border-bottom-color: $primary;
}
th .icon {
  height: unset;
  width: unset;
}
body {
  min-height:100%; padding:0; margin:0; position:relative;
}
body {
  position: relative;
}
body::after {
  content: '';
  display: block;
  height: 40px; /* Set same as footer's height */
}
.avatar {
  margin-top: -80px;
  margin-bottom: 20px;
}
.avatar img {
  padding: 40px 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
}
.input:not(:placeholder-shown):invalid {
  @extend .is-danger;
}
// .navbar {
//   box-shadow: 0 2px 3px rgba(#000, 0.1), 0 0 0 1px rgba(#000, 0.1)
// }
.stats.columns > .column > div {
  padding: 1.25rem 0;
  @extend .box;
}
.buttons .button.is-selected {
  @extend .is-primary;
}
ul {
  list-style-type: none;
  margin-top: 0;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top-color: rgba(0, 0, 0, 0.125);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.125);
  border-right-style: solid;
  border-right-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.125);
  border-left-style: solid;
  border-left-width: 1px;
}
.list-group-flush .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}
@include until($desktop) {
  .navbar-menu {
    padding-right: 15px;
    padding-left: 15px;

    .navbar-start, .navbar-end {
      .navbar-item {
        font-size: $size-4;
        border-bottom: 2px solid $athens-gray;
        padding: 15px 0;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url(./assets/fonts/GothamRounded-Light.eot);
  src: url(./assets/fonts/GothamRounded-Light.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/GothamRounded-Light.woff) format('woff'),
    url(./assets/fonts/GothamRounded-Light.ttf) format('truetype'),
    url(./assets/fonts/GothamRounded-Light.svg#GothamRounded-Light) format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url(./assets/fonts/GothamRounded-Medium.eot);
  src: url(./assets/fonts/GothamRounded-Medium.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/GothamRounded-Medium.woff) format('woff'),
    url(./assets/fonts/GothamRounded-Medium.ttf) format('truetype'),
    url(./assets/fonts/GothamRounded-Medium.svg#GothamRounded-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url(./assets/fonts/GothamRounded-Bold.eot);
  src: url(./assets/fonts/GothamRounded-Bold.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/GothamRounded-Bold.woff) format('woff'),
    url(./assets/fonts/GothamRounded-Bold.ttf) format('truetype'),
    url(./assets/fonts/GothamRounded-Bold.svg#GothamRounded-Bold) format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
